import {
  CardWithQuoteRow,
  isRichTextContentEmpty,
  RichText,
  toImageProps,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TCardWithQuoteRowProps = Extract<
  NonNullable<Section['rows']>[number],
  { blockType: 'card-with-quote-row' }
>;

const CardWithQuoteRowConnected: React.FC<TCardWithQuoteRowProps> = props => {
  const { cards } = props;
  return (
    <>
      {cards && (
        <CardWithQuoteRow
          cards={cards.map(i => ({
            ...i,
            topIcon: toImageProps(i?.topIcon),
            topImage: toImageProps(i?.topImage),
            statisticSize: i?.statsSize,
            description:
              i?.description && !isRichTextContentEmpty(i?.description) ? (
                <RichText content={i?.description} />
              ) : null,
            author: {
              ...i.author,
              icon: toImageProps(i?.author?.icon),
            },
          }))}
        />
      )}
    </>
  );
};

export default CardWithQuoteRowConnected;
